import * as React from "react";

type UnderContentLeadTextProps = {
  children: React.ReactNode;
  mbClass?: string;
};

export const UnderContentLeadText: React.FC<UnderContentLeadTextProps> = (
  props
) => {
  const { mbClass, children } = props;

  return (
    <p
      className={`text-center text-sm md:text-base leading-[1.83] px-5 md:px-0
      md:leading-loose font-bold ${
        mbClass ? mbClass : "mb-[46px] md:mb-[96px]"
      }`}
    >
      {children}
    </p>
  );
};
