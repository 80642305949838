import React from "react";

type BaseTitleProps = {
  en: string;
  ja: string;
  as?: React.ElementType;
  isMb?: boolean;
};

export const BaseTitle: React.FC<BaseTitleProps> = (props) => {
  const { en, ja, as: CustomTag = "h2", isMb = true } = props;

  return (
    <CustomTag
      className={`text-center text-sec-title tablet:!text-[24px] pc-small:text-[28px] md:text-[32px]
            font-bold antialiased ${isMb ? "mb-[40px] md:mb-[60px]" : ""}`}
    >
      {en}
      <span
        className="block text-center text-sec-title-ja
            font-medium pt-[2px] md:pt-[12px]"
      >
        {ja}
      </span>
    </CustomTag>
  );
};
