import * as React from "react";
import { BaseLayout } from "../../components/views/layouts/BaseLayout";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "../../hooks/useWindowSize";
import { UnderContentMv } from "../../components/templates/UnderContentMv";
import { UnderContentLeadText } from "../../components/parts/UnderContentLeadText";
import { SliderCafeContents } from "../../components/views/SliderCafeContents";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import { SliderBeansContents } from "../../components/views/SliderBeansContents";
import { ViewMoreButton } from "../../components/parts/ViewMoreButton";
import { ONLINE_SHOP_URL } from "../../constants/urls";
import { SliderWholesaleContents } from "../../components/views/SliderWholesaleContents";
import { SliderSupportContents } from "../../components/views/SliderSupportContents";
import { BcItem } from "../../components/views/Breadcrumb";
import { Helmet } from "react-helmet";

const bcList: BcItem[] = [{ href: "", text: "SERVICE" }];

const ServicePage = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <BaseLayout
      bcList={bcList}
      pageUrl="/service"
      pageTitle="SERVICE"
      pageDescription="お家でもコーヒーを楽しんでいただけるようにセレクトしたコーヒー豆やコーヒーグッズ。カフェやレストラン、美容室など事業者向けに業務用コーヒー豆の卸販売や、トレーニング等も行っています。"
    >
      <UnderContentMv jaTitle="暮らしに溶け込むコーヒー" enTitle="SERVICE">
        {windowWidth < 480 ? (
          <StaticImage
            src="../../images/mv/service.jpg"
            alt="SERVICE メイン画像"
          />
        ) : (
          <div className="h-[420px] lg:h-[580px]">
            <StaticImage
              src="../../images/mv/service_pc.jpg"
              alt="SERVICE メイン画像"
              className="absolute inset-y-0 m-auto"
            />
          </div>
        )}
      </UnderContentMv>
      <UnderContentLeadText>
        お店に訪れて楽しむバリスタが淹れたコーヒー
        <br />
        お家でもコーヒーを楽しんでいただけるようにセレクトしたコーヒー豆やコーヒーグッズ
        <br />
        カフェやレストラン、美容室など事業者向けに業務用コーヒー豆の卸販売や、
        <br className="hidden md:block" />
        トレーニング等も行っています。
      </UnderContentLeadText>
      <section className="mb-[60px] lg:mb-[90px]" id="cafe">
        <div className="lg:flex lg:justify-center lg:items-center relative overflow-hidden lg:mb-[60px]">
          <div
            className="absolute w-[100%] h-[100%] absolute top-0 left-0
          bg-white z-[1] bg-opacity-10 pointer-events-none w-[100%] z-[2]"
          />
          <div className="w-[100%] h-[100%] relative">
            <div className="w-[100%] h-[100%] relative">
              <SliderCafeContents
                title="Cafe"
                subtitle="喫茶"
                isTopPage={false}
                position="right"
              >
                <p className="text-center mb-[30px]">
                  10種類以上のコーヒーと
                  <br className="hidden xl:block" />
                  アレンジドリンク
                </p>
              </SliderCafeContents>
            </div>
          </div>
        </div>
        <div className="max-w-[1020px] mx-auto px-5 -mt-[34px] xl:mt-0 mb-[30px] md:mb-[70px] pt-4 md:pt-0">
          <h3 className="text-center xl:text-lg font-bold antialiased mb-[30px] lg:mb-[50px]">
            <span className="text-[1.4em] text-secondary">コーヒー</span>{" "}
            のある時間
          </h3>
          <p>
            お店では、種類豊富なコーヒーに、カフェラテなどのアレンジドリンクもお楽しみいただけます。
            <br />
            ドリンクを飲みながら、ゆっくりと過ごしたり、友人と何気ない会話をして過ごしたり。バリスタにお家でコーヒーを楽しむコツを聞いたりするのも一つです。
            日常の中のちょっとしたひと時に、カフェでの時間をお楽しみ下さい。
          </p>
        </div>
        <div className="px-5 mb-[60px] md:mb-[80px]">
          {windowWidth < 768 ? (
            <Swiper
              modules={[Autoplay, EffectFade]}
              effect="fade"
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_cafe_01.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_cafe_02.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_cafe_03.png"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="flex items-center justify-between max-w-[1240px] mx-auto">
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_cafe_01.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_cafe_02.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_cafe_03.png"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <ViewMoreButton
          href="/contact?iqr=0"
          isPrimary={true}
          text="お問い合わせ"
        />
      </section>
      <section className="mb-[60px] lg:mb-[90px]" id="beans-supplies">
        <div className="lg:flex lg:justify-center lg:items-center relative overflow-hidden lg:mb-[60px]">
          <div
            className="absolute w-[100%] h-[100%] absolute top-0 left-0
          bg-white z-[1] bg-opacity-10 pointer-events-none w-[100%] z-[2]"
          />
          <div className="w-[100%] h-[100%] relative">
            <div className="w-[100%] h-[100%] relative">
              <SliderBeansContents
                title="Beans<br />& Supplies"
                subtitle="コーヒー豆・関連用品の販売"
                isTopPage={false}
                position="left"
              >
                <p className="text-center mb-[30px]">
                  お家で、気軽に
                  <br />
                  美味しいコーヒーを
                </p>
              </SliderBeansContents>
            </div>
          </div>
        </div>
        <div className="max-w-[1020px] mx-auto px-5 -mt-[34px] xl:mt-0 mb-[30px] md:mb-[70px] pt-4 md:pt-0">
          <h3 className="text-center xl:text-lg font-bold antialiased mb-[30px] lg:mb-[50px]">
            日常の中に{" "}
            <span className="text-[1.4em] text-secondary">小さな幸せ</span> を
          </h3>
          <p>
            LOG COFFEE
            ROASTERSでは、常時10種類以上のスペシャルティコーヒーを取り扱っています。
            <br />
            たくさんある中から、その時に飲みたいコーヒー豆を選ぶ。お家で、挽いて、淹れて、飲む。シンプルでちょっとした事だけど、この時間があることで、日常が少し豊かになる。私たちは、そんな時間を楽しんでいただけるよう、ちょっとしたお手伝いをさせていただいています。
          </p>
        </div>
        <div className="px-5 mb-[60px] md:mb-[80px]">
          {windowWidth < 768 ? (
            <Swiper
              modules={[Autoplay, EffectFade]}
              effect="fade"
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_beans_01.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_beans_02.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_beans_03.png"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="flex items-center justify-between max-w-[1240px] mx-auto">
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_beans_01.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_beans_02.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_beans_03.png"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="md:flex md:items-center md:justify-center">
          <div className="md:mx-[30px] mb-10 md:mb-0">
            <ViewMoreButton
              href="/contact?iqr=0"
              isPrimary={true}
              text="お問い合わせ"
            />
          </div>
          <div className="md:mx-[30px]">
            <ViewMoreButton
              href={ONLINE_SHOP_URL}
              isPrimary={false}
              isExterior={true}
              text="オンラインショップ"
            />
          </div>
        </div>
      </section>
      <section className="mb-[60px] lg:mb-[90px]" id="wholesale">
        <div className="lg:flex lg:justify-center lg:items-center relative overflow-hidden lg:mb-[60px]">
          <div
            className="absolute w-[100%] h-[100%] absolute top-0 left-0
          bg-white z-[1] bg-opacity-10 pointer-events-none w-[100%] z-[2]"
          />
          <div className="w-[100%] h-[100%] relative">
            <div className="w-[100%] h-[100%] relative">
              <SliderWholesaleContents
                title="Wholesale"
                subtitle="事業者様向け卸販売"
                isTopPage={false}
                position="right"
              >
                <p className="text-center mb-[30px]">
                  事業者様向けのコーヒー豆販売、
                  <br />
                  コーヒー関連機器の選定・販売
                </p>
              </SliderWholesaleContents>
            </div>
          </div>
        </div>
        <div className="max-w-[1020px] mx-auto px-5 -mt-[34px] xl:mt-0 mb-[30px] md:mb-[70px] pt-4 md:pt-0">
          <h3 className="text-center xl:text-lg font-bold antialiased mb-[30px] lg:mb-[50px]">
            コーヒーを{" "}
            <span className="text-[1.4em] text-secondary">より美味しく</span>{" "}
            するため
          </h3>
          <p>
            LOG COFFEE
            ROASTERSでは、カフェやレストラン、美容室やアパレルショップなど、コーヒーをご提供される会社、店舗様へコーヒー豆の卸販売をさせていただいています。店舗様の独自のオリジナルブレンドも、好みの味をヒアリングし、テイスト調整を重ねながら、一緒に作っていきます。
            <br />
            「たかがコーヒー、されどコーヒー」。食事中や食後、待ち時間中のコーヒーが美味しくなるだけで、お客様はより喜んでいただけます。そのためのお手伝いを、私たちはさせていただいています。
          </p>
        </div>
        <div className="px-5 mb-[60px] md:mb-[80px]">
          {windowWidth < 768 ? (
            <Swiper
              modules={[Autoplay, EffectFade]}
              effect="fade"
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_wholesale_01.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_wholesale_02.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_wholesale_03.png"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="flex items-center justify-between max-w-[1240px] mx-auto">
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_wholesale_01.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_wholesale_02.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_wholesale_03.png"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="md:flex md:items-center md:justify-center">
          <div className="md:mx-[30px] mb-10 md:mb-0">
            <ViewMoreButton
              href="/contact?iqr=1"
              isPrimary={true}
              text="お問い合わせ"
            />
          </div>
          <div className="md:mx-[30px]">
            <ViewMoreButton
              href="/service/for-business"
              isPrimary={false}
              isExterior={false}
              text="詳しくはこちら"
            />
          </div>
        </div>
      </section>
      <section className="mb-[60px] lg:mb-[90px]" id="support">
        <div className="lg:flex lg:justify-center lg:items-center relative overflow-hidden lg:mb-[60px]">
          <div
            className="absolute w-[100%] h-[100%] absolute top-0 left-0
          bg-white z-[1] bg-opacity-10 pointer-events-none w-[100%] z-[2]"
          />
          <div className="w-[100%] h-[100%] relative">
            <div className="w-[100%] h-[100%] relative">
              <SliderSupportContents
                title="Support"
                subtitle="サポート業務"
                isTopPage={false}
                position="left"
              >
                <p className="text-center mb-[30px]">
                  コーヒーに関することの
                  <br className="hidden xl:block" />
                  トータルサポートを致します
                </p>
              </SliderSupportContents>
            </div>
          </div>
        </div>
        <div className="max-w-[1020px] mx-auto px-5 -mt-[34px] xl:mt-0 mb-[30px] md:mb-[70px] pt-4 md:pt-0">
          <h3 className="text-center xl:text-lg font-bold antialiased mb-[30px] lg:mb-[50px]">
            バリスタトレーニングから、
            <br />
            メンテナンス方法まで何でもお気軽に
          </h3>
          <p>
            LOG COFFEE
            ROASTERSでは、クライアント様にバリスタトレーニングから機器のメンテナンス方法など、コーヒーに関することは、しっかりとサポートさせていただきます。
            <br />
            どんなに良いコーヒー豆でも、抽出によってコーヒーは簡単に「不味く」もなります。定期的なメンテナンスの有無でも味は変わってしまいます。
            <br />
            クライアント様、クライアント様のお客様にも「コーヒー美味しいね」と喜んでいただけるよう、サポートをさせていただいております。
          </p>
        </div>
        <div className="px-5 mb-[60px] md:mb-[80px]">
          {windowWidth < 768 ? (
            <Swiper
              modules={[Autoplay, EffectFade]}
              effect="fade"
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_support_01.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_support_02.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/service/service_support_03.png"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="flex items-center justify-between max-w-[1240px] mx-auto">
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_support_01.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_support_02.png"
                  alt=""
                />
              </div>
              <div className="w-[27%]">
                <StaticImage
                  src="../../images/service/service_support_03.png"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="md:flex md:items-center md:justify-center">
          <div className="md:mx-[30px] mb-10 md:mb-0">
            <ViewMoreButton
              href="/contact?iqr=2"
              isPrimary={true}
              text="お問い合わせ"
            />
          </div>
          <div className="md:mx-[30px]">
            <ViewMoreButton
              href="/service/for-business#for-business-support"
              isPrimary={false}
              isExterior={false}
              text="詳しくはこちら"
            />
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default ServicePage;
