import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const setWindowSizes = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (!window) return;
    setWindowSizes();

    window.addEventListener("resize", setWindowSizes);

    return () => {
      window.removeEventListener("resize", setWindowSizes);
    };
  }, []);

  return [width, height];
};
