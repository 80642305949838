import React, { ReactNode } from "react";

import { BaseTitle } from "../parts/BaseTitle";

type UnderContentMvProps = {
  children: ReactNode;
  jaTitle: string;
  enTitle: string;
};
export const UnderContentMv: React.FC<UnderContentMvProps> = (props) => {
  const { jaTitle, enTitle, children } = props;

  return (
    <div className="under-content-mv relative mb-[70px] md:mb-[120px]">
      <div className="absolute w-[100%] h-[100%] inset-0 m-auto bg-white bg-opacity-40 z-10"></div>
      {children}
      <div
        className="bg-white w-[calc(100%_-_40px)] md:w-[87.5%] mx-auto rounded-tr-md rounded-tl-md
          pt-3 md:pt-10 absolute -bottom-4 md:bottom-0 inset-x-0 z-10"
      >
        <BaseTitle en={enTitle} ja={jaTitle} as="h1" isMb={false} />
      </div>
    </div>
  );
};
